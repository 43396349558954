import React, { Component } from 'react'

class NotFoundPage extends Component {
    state = {  }
    render() { 
        return ( 
            <React.Fragment>
                <h1>404 Not Found</h1>
                <p>Go home you fucked up</p>
            </React.Fragment> 
        );
    }
}
 
export default NotFoundPage;