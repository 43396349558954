import global from './../global';
import axios from 'axios';

const URL = `${global.getUrl()}/auth`;

class AuthService {
    
    login (email, password) {
        return axios.post(`${URL}/login`, { email, password })
        .then(res => {
            if (res.data.token) {
                localStorage.setItem('user', JSON.stringify(res.data));
            }
            return res.data;
        })
    }

    register (email, name, password) {
        return axios.post(`${URL}/register`, {email, name, password})
        .then(res => {
            if (res.data.token) {
                localStorage.setItem('user', JSON.stringify(res.data));
            }
            console.log({email, name, password});
            return res.data;
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401 || error.response.status === 403) {
                    return { success: false, error };
                }
            }
        })
    }

    logout() {
        localStorage.removeItem('user');
    }

    getUser() {
        return JSON.parse(localStorage.getItem('user'));
    }

    authHeader() {
        const user = this.getUser();

        if (user && user.token) {
            return { token: user.token };
        } else {
            return { }
        }
    }
}

export default new AuthService();