import React, { Component } from 'react'
import { Container, Form, Button } from 'react-bootstrap';
import userService from '../services/user-service';

class SettingsPage extends Component {


    state = { url: "", name: "" }

    handleURLChange = (event) => {
        this.setState({url: event.target.value});
    }

    handleNameChange = (event) => {
        this.setState({name: event.target.value})
    }

    render() {
        return(
            <Container>
                <h1>Change profile image</h1>
                <Form>
                    <Form.Group>
                        <Form.Label>Change profile image</Form.Label>
                        <Form.Control type="url" placeholder='URL here' onChange={this.handleURLChange} value={this.state.url} />
                    </Form.Group>
                    <Button variant="primary" onClick={() => userService.changeProfileImage(this.state.url)}>
                        Change
                    </Button>
                </Form>
                <Form>
                    <Form.Group>
                        <Form.Label>Change name</Form.Label>
                        <Form.Control type="text" placeholder='Name here' onChange={this.handleNameChange} value={this.state.name} />
                    </Form.Group>
                    <Button variant="primary" onClick={() => userService.changeName(this.state.url)}>
                        Change
                    </Button>
                </Form>
            </Container>
        )
    }
}

export default SettingsPage;