import React, { Component } from 'react'
import { Link } from 'react-router-dom';

class Footer extends Component {
    state = {  }
    render() { 
        return ( 
            <div className="footer">
                <hr></hr>
                <span className="copyright"><a href="https://harry.is-a.dev/">Harry Ross</a> © 2020</span>
            </div>
        );
    }
}
 
export default Footer;