import axios from 'axios';
import AuthService from './auth-service';
import DataService from './data-service';
import global from '../global';

const url = global.getUrl();
const lastfmurl = global.getLastFmUrl()

class SearchService {
    async getAlbums(query) {
        const lastfm_token = await DataService.getLastFmKey();
        let res = await axios.get(`${lastfmurl}?method=album.search&album=${query}&api_key=${lastfm_token}&format=json&limit=8`);
        return res.data;
    }
    getArtists(query) {

    }
}

export default new SearchService();