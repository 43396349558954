import React, { Component } from 'react';
import FeedItem from '../components/feed-item';
import { CardDeck, Card, Container } from 'react-bootstrap';

import './../styles/feed.scss';
import postService from '../services/post-service';
import { Link } from 'react-router-dom';

class FeedPage extends Component {
    state = { data: {}, loading: true }

    componentDidMount() {
        postService.getFeed().then(val => {
            this.setState({
                data: val,
                loading: false
            })
            console.log(val);
        })
    }

    render() { 
        return ( 
            !this.state.loading
            ? this.state.data.length !== 0 
                ?<Container>
                    <div className="feed">          
                        {this.state.data.map(item => 
                            <FeedItem item = {item} />
                        )}
                    </div>    
                </Container>
                : <p>No posts here - go find people to follow on the explore page <Link to='/explore'>here.</Link></p>
            : <p>loading...</p>
         );
    }
}
 
export default FeedPage;