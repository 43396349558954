import React, { Component } from 'react';
import { Alert, Form, Button, Container } from 'react-bootstrap';
import { Redirect } from 'react-router';

class RegisterPage extends Component {
    state = { 
        name: "",
        email: "",
        password: "",
    }

    handleNameChange = (event) => {
        this.setState({name: event.target.value});
    }

    handleEmailChange = (event) => {
        this.setState({email: event.target.value});
    }

    handlePasswordChange = (event) => {
        this.setState({password: event.target.value});
    }

    render() { 
        if (localStorage.getItem('user')) {
            return <Redirect to="/feed"></Redirect>
        }
        return ( 
            <div className="login-container">
                {this.state.error 
                ? <Alert variant="danger">
                    <p>Try entering another email or password</p>
                </Alert> 
                : <br />
                }
                <Container fluid="sm">
                    <Form>
                        <Form.Group>
                            <Form.Label>Name (can be fake if you want)</Form.Label>
                            <Form.Control type="text" placeholder='John Smith' onChange={this.handleNameChange} value={this.state.name} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder='Email' onChange={this.handleEmailChange} value={this.state.email} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Something secure" value={this.state.password} onChange={this.handlePasswordChange} />
                        </Form.Group>
                        <Button variant="primary"onClick={() => this.props.handleRegister(this.state.email, this.state.name, this.state.password)}>
                            Register
                        </Button>
                    </Form>
                </Container>
            </div>
         );
    }
}
 
export default RegisterPage;