import React, { Component } from 'react'
import { Container, Form, Button } from 'react-bootstrap'
import LogForm from '../components/log-form';

class LogPage extends Component {
    

    render () {
        return(
            <Container>
                <LogForm />
            </Container>
        )
    }
}

export default LogPage;