import React, { Component } from 'react'
import { Image } from 'react-bootstrap'

class AlbumImage extends Component {
    render() {
        let src = this.props.src || "https://discussions.apple.com/content/attachment/881765040";
        let size = this.props.size || 150;
        return(
            <Image src={src} width={size} height={size} rounded />
        )
    }
}

export default AlbumImage;