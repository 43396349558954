import React, { Component } from 'react';
import ArtistService from '../services/artist_service';
import { withRouter } from 'react-router';
import { Container } from 'react-bootstrap';
import ProfileImage from '../components/profile-img';
import '../styles/artist.scss';
import AlbumImage from '../components/album-img';
import { Link } from 'react-router-dom';

class ArtistPage extends Component {
    state = { albums: null, name: "", artist_img: "https://discussions.apple.com/content/attachment/881765040" }

    componentDidMount() {
        const name = this.props.match.params.artist;
        this.getArtistName(name);
        this.getAlbumList(name);
    }

    getAlbumList(name) {
        ArtistService.getTopAlbums(name).then(val => {
            this.setState({
                albums: val
            })
        })
    }

    getArtistName(artist) {
        ArtistService.getArtistInfo(artist).then(val => {
            this.setState({
                name: val.name
            })
        })
    }

    render() { 
        return ( 
            <div>
                <Container fluid="sm">
                    <div className="artist-header">
                        <ProfileImage size={300} src={this.state.artist_img} />
                        <div className="artist-name">{this.state.name}</div>
                        <div className="artist-listeners">100k listeners</div>
                    </div>
                </Container>
                <Container className="artist-content">
                    <div className="artist-albums">
                    { this.state.albums 
                        ? this.state.albums.map(album => 
                            <Link to={`/album/${this.state.name}/${album.name}`}>
                            <span className="artist-album">
                                <AlbumImage src={album.image} />
                                {/* <span>{album.name}a</span> */}
                            </span>
                            </Link>
                        ) 
                        : <p>Loading...</p> }
                    </div>
                </Container>
            </div>
        );
    }
}
 
export default withRouter(ArtistPage);