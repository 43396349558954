import React, { Component } from 'react'
import '../styles/user.scss';
import userService from '../services/user-service';
import { Route, Switch } from 'react-router-dom';
import { Container, Button, Spinner } from 'react-bootstrap';
import FeedItem from '../components/feed-item';
import { PlusIcon, DashIcon } from '@primer/octicons-react'
import ProfileImage from '../components/profile-img';

class UserPage extends Component {
    state = { 
        user_data: {},
        following: false,
        loading: true,
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        userService.getUser(id).then(val => {
            console.table(val);
            this.setState({
                user_data: val,
                loading: false,
                following: val.following_user
            })
        })
    }

    followUser() {
        userService.followUser(this.props.match.params.id).then(val => {
            console.log(val)
            this.setState({
                following: !this.state.following,
            })
        })
    }

    render() { 
        let match = this.props.match;
        let { posts, followers, following, profile_img, name } = this.state.user_data;
        return ( 
            !this.state.loading
            ?<div>
                <Container fluid="sm"> 
                    <div className="user-header">
                        <ProfileImage size={300} src={profile_img} /> 
                        <div className="user-name">{`${name}`}</div>
                        <div className="user-post-count">Posts: {posts.length}</div>
                        <div className="user-followers">Followers: {followers.length}</div>
                        <div className="user-following">Following: {following.length}</div>
                        {this.state.following
                            ? <Button variant="light" onClick={() => {this.followUser()}}><DashIcon size={16} />Unfollow</Button>
                            : <Button variant="primary" onClick={() => {this.followUser()}}><PlusIcon size={16} />Follow</Button>
                        }
                        
                    </div>
                </Container>
                <Container>
                    <Switch>    
                        <Route path={`${match.path}/posts`}>

                        </Route>
                        <Route path={`${match.path}/followers`}>
                            {this.state.user_data.followers.map(user => {

                            })}
                        </Route>
                        <Route path={`${match.path}/following`}>
                            {this.state.user_data.following.map(user => {
                                
                            })}
                        </Route>
                        <Route path={`${match.path}`}>
                            {this.state.user_data.posts.map(post => 
                                <FeedItem item={post}/>
                            )}
                        </Route>
                    </Switch>
                </Container>
                
            </div>
            : <Spinner animation="border"/>
        );
    }
}
 
export default UserPage;