import React, { Component } from 'react'
import { Image } from 'react-bootstrap'
import AlbumImage from './album-img';
import { Link } from 'react-router-dom';

const size = 160;

class ExploreAlbum extends Component {
    getListeners(listeners) {
        if (listeners >= 1000) {
            let decimalListeners = listeners/1000;
            return decimalListeners.toFixed(1) + "k"
        }
        return listeners;
    }

    render() {
        let { albumart, name, listeners, artist } = this.props.data;
        return (
            <div className="explore-album">
                <Link to={`/album/${artist}/${name}`}>
                    <AlbumImage size={size} src={albumart}></AlbumImage>
                    <div className="explore-user-name">{name}</div>
                    <div className="explore-user-followers">{this.getListeners(listeners)} listeners</div>
                </Link>
            </div>
        )
    }
}

export default ExploreAlbum;