import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import ExploreUser from './../components/explore-user';
import '../styles/explore.scss';

import ExploreService from '../services/explore-service';
import ExploreAlbum from '../components/explore-album';
import { Spinner } from 'react-bootstrap';

class ExplorePage extends Component {
    state = { 
        users: [],
        albums: [],
        albums_loading: true,
        users_loading: true,
    }

    componentDidMount() {
        ExploreService.getUsers().then(val => {
            this.setState({
                users: val.sort((a, b) => b.followers - a.followers),
                users_loading: false,
            })
        })
        ExploreService.getAlbums().then(val => {
            this.setState({
                albums: val,
                albums_loading: false
            })
        })
    }

    render() { 
        return (
            <Container>
                <div className="explore-title">People to follow</div>
                {!this.state.users_loading
                ? <div className="explore-container">
                    {this.state.users.map(item =>
                        (<ExploreUser user={item} />)
                    )}
                </div>
                : <Spinner animation="border"/>
                }
                <div className="explore-title">Popular albums</div>
                {!this.state.albums_loading
                ? <div className="explore-container">
                    {this.state.albums.map(item => 
                        (<ExploreAlbum data={item} />)
                    )}
                </div>
                : <Spinner animation="border"/>
                }
            </Container>
        );
    }
}
 
export default ExplorePage
