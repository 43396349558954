import React, { Component } from 'react';
import searchService from '../services/search-service';
import { Link } from 'react-router-dom';
import { Form, FormControl, Button } from 'react-bootstrap';
import Autosuggest from 'react-autosuggest';

class SearchBox extends Component {

    state = { 
        results: ["asdads", "dasds"],
        loading: false,
        searchInput: ""
    }

    searchChange = (input) => {
        const query = input.trim().toLowerCase();
        if (!query) { this.setState({ loading: false }) }
        else { this.setState({ loading: true }) }
        if (!this.state.loading && query) {
            searchService.getAlbums(query).then(val => {
                const albums = val.results.albummatches.album;
                const items = albums.map(item => { 
                    return ({ 
                        title: item.name, 
                        description: item.artist, 
                        image: item.image[2]["#text"], 
                        as: Link, 
                        to: `/album/${item.artist}/${item.name}` 
                    })
                });
                console.log(items);
                this.setState({ loading: false, results: items });
            })
        }
    }

    inputChange = (event) => {
        this.setState({ searchInput: event.target.value });
    }

    renderSuggestion = (suggestion) => (
        <div>
            { suggestion.title }
        </div>
    )

    render() { 
        return ( 
            // <Search
            //     loading={this.state.loading}
            //     onSearchChange={this.searchChange}
            //     results={this.state.results}
            // />
            // <Form inline>
            //     <FormControl type="text" placeholder="Search" className="mr-sm-2" />
            // </Form>
            <input 
                type="text"
                value={this.state.searchInput}
                onChange={this.inputChange}
            />
        );
    }
}
 
export default SearchBox;