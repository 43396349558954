import global from './../global';
import axios from 'axios';
import AuthService from './auth-service';

const URL = global.getUrl();

class DataService {
    getPosts () {
        //axios.get(`${URL}/posts`, { headers: authHeader() })
        return [{
            user: "Harry",
            albumtitle: "Days Before Rodeo",
            artist: "Travis Scott",
            albumart: "https://i1.sndcdn.com/artworks-000088468287-jju25j-t500x500.jpg"
        },
        {
            user: "Harry",
            albumtitle: "Rodeo",
            artist: "Travis Scott",
            albumart: "https://upload.wikimedia.org/wikipedia/en/0/02/Rodeoalbum.jpg"
        }];
    }

    async getLastFmKey() {
        if (localStorage.getItem('lastfm')) {
            return localStorage.getItem('lastfm');
        } else {
            let res = await axios.get(`${URL}/auth/lastfm`, { headers: AuthService.authHeader() })
            localStorage.setItem('lastfm', res.data.key);
            return res.data.key;
        }
    }
}

export default new DataService();