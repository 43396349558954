import React, { Component } from 'react'
import { StarFillIcon } from '@primer/octicons-react'
import userService from '../services/user-service';

class AlbumComment extends Component {
    state = { user: {} }

    componentDidMount() {
        userService.getUser(this.props.user).then(val => {
            this.setState({
                user: val
            })
        })
    }

    render() { 
        return (  
            <div className="album-comment">
                <div className="album-comment-user">{this.state.user.name}</div>
                {this.props.content
                    ? <div className="album-comment-content">{this.props.content}</div>
                    : null
                }
                {this.props.rating
                    ? <div className="album-comment-rating">
                        {[...Array(this.props.rating)].map(() => {
                            return (<StarFillIcon className="album-comment-star" />)
                        })}
                    </div>
                    : null
                }
            </div>
        );
    }
}
 
export default AlbumComment;