import global from "../global";
import axios from 'axios';
import authService from './auth-service';

const URL = `${global.getUrl()}`;

class PostService {
    async newPost(artist, album, content, rating) {
        let res = await axios.post(`${URL}/post/new`, {artist, album, content, rating}, { headers: authService.authHeader() });
        return res.data;    
    }

    async getFeed() {
        let res = await axios.get(`${URL}/feed`, { headers: authService.authHeader() });
        return res.data.posts;
    }
}

export default new PostService();