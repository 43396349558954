// Import base npm packages that builds page
import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Import different pages for routing
import LoginPage from './pages/Login';
import RegisterPage from './pages/Register';
import HomePage from './pages/Home';
import FeedPage from './pages/Feed';
import NotFoundPage from './pages/NotFound';
import AlbumPage from './pages/Album';
import ArtistPage from './pages/Artist'
import ExplorePage from './pages/Explore'
import SettingsPage from './pages/Settings'
import PrivateRoute from './components/private-route';
import UserPage from './pages/User';
import LogPage from './pages/Log';

// Import base components of web app
import Navbar from './components/navbar';
import Footer from './components/footer';
import LogModal from './components/log-modal';

// Import services to interact with backend
import AuthService from './services/auth-service';

// Base app class
class App extends Component {
    constructor(props) {
        super(props)
        this.state = { 
            loggedIn: null,
            show: false,
            loginErr: "",
            registerErr: ""
        }
        this.handleLogin.bind(this);
    }

    // Code to run when app is initialised
    componentDidMount() {
        if (localStorage.getItem('user')) {
            this.setState({
                loggedIn: true
            })
        } else {
            console.log("got to here")
            this.setState({
                loggedIn: false
            })
        }
    }

    // Method to handle a login, which is passed to login page through props
    handleLogin = (email, password) => {
        AuthService.login(email, password)
        .then(() => {
            this.setState({loggedIn: true});
            this.props.history.push('/feed');
        }).catch(err => {
            console.log(err)
            if (err.response) {
                this.setState({
                    loginErr: err.response.data.message
                });
            }
        });
    }

    // Method to handle a register, which is passed to register page through props
    handleRegister = (email, name, password) => {
        AuthService.register(email, name, password)
        .then(() => {
            this.setState({loggedIn: true});
            this.props.history.push('/feed');
        }).catch(err => {
            if (err.response) {
                this.setState({
                    registerErr: err.response.data.message
                });
            }
        });
    }

    // Method to handle logout, is triggered when logout button is pressed on navbar
    handleLogout = () => {
        AuthService.logout()
        this.setState({
            loggedIn: false
        })
    }

    // Method that shows the log music modal, passed to components that need it through props
    showModal = () => {
        this.setState({
            show: true
        })
    }

    // Method that shows the log music modal, passed to components that need it through props
    hideModal = () => {
        this.setState({
            show: false
        })
    }

    // Method that renders the application
    render() { 
        return ( 
            <Router>
                {/* Displays navbar and loads modal for when it needs to be displayed at top of base page */}
                <Navbar 
                    loggedIn={this.state.loggedIn} 
                    handleLogout={this.handleLogout} 
                    showModal={this.showModal}
                />
                <LogModal show={this.state.show} hideModal={this.hideModal} />
                
                {/* Displays respective page defined within switch when on a specified subdomain */}
                <Switch>
                    <Route exact path={["/", "/home"]} component={HomePage} />
                    <PrivateRoute exact path="/feed">
                        <FeedPage />
                    </PrivateRoute>
                    <PrivateRoute exact path="/explore">
                        <ExplorePage />
                    </PrivateRoute>
                    <PrivateRoute exact path="/settings">
                        <SettingsPage />
                    </PrivateRoute>
                    <Route 
                        exact path="/login" 
                        render={() => <LoginPage error={this.state.loginErr} handleLogin={this.handleLogin} />}
                    />
                    <Route 
                        exact path="/register" 
                        render={() => <RegisterPage error={this.state.registerErr} handleRegister={this.handleRegister} />}
                    />
                    <Route exact path="/album/:artist/:name" component={AlbumPage}  />
                    <Route exact path="/artist/:artist" component={ArtistPage}  />
                    <Route exact path="/log" component={LogPage} />
                    <Route exact path="/user/:id" component={UserPage} />
                    <Route component={NotFoundPage} />
                </Switch>

                {/* Displays footer at bottom of each page */}
                <Footer />
            </Router>
        );
    }
}

// Exports App class so it can be accessed by other files, and allows for it to be displayed
export default App;