import global from './../global';
import axios from 'axios';
import AuthService from './auth-service';
import DataService from './data-service';

const URL = global.getUrl();
const lastfm_url = global.getLastFmUrl();

class AlbumService {
    async getAlbumInfo (artist, name) {
        let res = await axios.get(`${URL}/albums/${artist}/${name}`, { headers: AuthService.authHeader() });
        const lastfm_token = await DataService.getLastFmKey();
        let lastfmres = await axios.get(`${lastfm_url}?method=album.getinfo&api_key=${lastfm_token}&artist=${artist}&album=${name}&format=json`);
        return {
            name: lastfmres.data.album.name,
            artist: lastfmres.data.album.artist,
            image: await this.getAlbumArt(artist, name, 2),
            comments: res.data.comments,
            listener_count: res.data.listener_count,
        }
    }

    async getAlbumArt (artist, name, size) {
        const lastfm_token = await DataService.getLastFmKey();
        try {
            let lastfmres = await axios.get(`${lastfm_url}?method=album.getinfo&api_key=${lastfm_token}&artist=${artist}&album=${name}&format=json`);
            return lastfmres.data.album.image[size]["#text"];
        } catch (e) {
            console.error(e)
        }
    }
}

export default new AlbumService()