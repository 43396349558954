import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import LogForm from './log-form';
import postService from '../services/post-service'

class LogModal extends Component {

    render() {
        return(
            <Modal show={this.props.show} onHide={this.props.hideModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Log album</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LogForm submitClicked={this.props.hideModal} />
                </Modal.Body>
            </Modal>
        )
    }
}

export default LogModal;