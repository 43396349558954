import React, { Component } from 'react'
import { Form, Button } from 'react-bootstrap';

import postService from '../services/post-service';

class LogForm extends Component {

    state = {
        artist: "",
        album: "",
        comment: "",
        rating: 1
    }

    handleArtistChange = (event) => {
        this.setState({artist: event.target.value});
    }
    handleAlbumChange = (event) => {
        this.setState({album: event.target.value});
    }
    handleCommentChange = (event) => {
        this.setState({comment: event.target.value});
    }
    handleRatingChange = (event) => {
        this.setState({rating: event.target.value});
    }

    handleSubmit = () => {
        postService.newPost(this.state.artist, this.state.album, this.state.comment, this.state.rating).then(val => {
            console.log(val)
        });
        this.props.submitClicked()
    }

    render() {
        return (
            <Form>
                <Form.Group>
                    <Form.Label>Artist</Form.Label>
                    <Form.Control required onChange={this.handleArtistChange} value={this.state.artist}></Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Album</Form.Label>
                    <Form.Control required onChange={this.handleAlbumChange} value={this.state.album}></Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Comment</Form.Label>
                    <Form.Control onChange={this.handleCommentChange} value={this.state.comment}></Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Rating</Form.Label>
                    <Form.Control onChange={this.handleRatingChange} as="select" value={this.state.rating}>
                        <option>0</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                    </Form.Control>
                </Form.Group>
                <Button variant="primary" onClick={this.handleSubmit}>
                    Submit
                </Button>
            </Form>
        )
    }
}

export default LogForm;