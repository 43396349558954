import global from '../global';
import axios from 'axios';
import AuthService from './auth-service';

const url = global.getUrl();

class ExploreService {
    async getAlbums() {
        let res = await axios.get(`${url}/explore/albums`, { headers: AuthService.authHeader() })
        return res.data.albums;
    }

    async getUsers() {
        let res = await axios.get(`${url}/explore/users`, { headers: AuthService.authHeader() })
        return res.data.users;
    }

    async getArists() {
        let res = await axios.get(`${url}/explore/artists`, { headers: AuthService.authHeader() })
        return res.data;
    }
}

export default new ExploreService();