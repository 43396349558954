import React, { Component } from 'react'
import { Image } from 'react-bootstrap';

class ProfileImage extends Component {
    render() {  
        let src = this.props.src || "https://t4.ftcdn.net/jpg/00/64/67/63/240_F_64676383_LdbmhiNM6Ypzb3FM4PPuFP9rHe7ri8Ju.jpg"
        let size = this.props.size || 150;
        return(
            <Image roundedCircle height={size} width={size} src={src} />
        )
    }
}

export default ProfileImage;