import global from './../global';
import axios from 'axios';
import AuthService from './auth-service';

const url = global.getUrl();

class UserService {
    async getUser(user_id) {
        let res = await axios.get(`${url}/user/${user_id}`, { headers: AuthService.authHeader() });
        return res.data.user;
    }

    async followUser(user_id) {
        let res = await axios.post(`${url}/follow/${user_id}`, {}, { headers: AuthService.authHeader() });
        return true;
    }

    async changeProfileImage(profile_img) {
        let res = await axios.post(`${url}/user/profile_img`, { profile_img }, { headers: AuthService.authHeader() });
        return true;
    }

    async changeName(name) {
        let res = await axios.post(`${url}/user/name`, { name }, { headers: AuthService.authHeader() })
        return true;
    }
}

export default new UserService();