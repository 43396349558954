import React, { Component } from 'react';
import { Alert, Form, Button, Container } from 'react-bootstrap';
import { Redirect } from 'react-router';

class LoginPage extends Component {
    state = { error: 0 }
    constructor(props) {
        super(props);

        this.state = {
            email: "", 
            password: "",
        }
    }    

    handleEmailChange = (event) => {
        this.setState({email: event.target.value});
    }
 
    handlePasswordChange = (event) => {
        this.setState({password: event.target.value});
    }
    
    render() { 
        if (localStorage.getItem('user')) {
            return <Redirect to="/feed"></Redirect>
        }
        return ( 
            <div className="login-container">
                {this.state.error 
                ? <Alert variant="danger">
                    <p>Try entering another email or password</p>
                </Alert> 
                : <br />
                }
                <Container fluid="sm">
                    {this.props.error
                        ? <Alert variant="danger">{this.props.error}</Alert>
                        : null
                    }
                    <Form>
                        <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder='Email' onChange={this.handleEmailChange} value={this.state.email} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" value={this.state.password} onChange={this.handlePasswordChange} />
                        </Form.Group>
                        <Button variant="primary" onClick={() => this.props.handleLogin(this.state.email, this.state.password)}>
                            Login
                        </Button>
                    </Form>
                </Container>
            </div>
        );
    }
}
 
export default LoginPage;