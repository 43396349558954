import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Navbar, Nav, Button, Form, Dropdown } from "react-bootstrap";
import { PlusIcon } from '@primer/octicons-react'

import SearchBox from '../components/search-box';
import ProfileImage from './profile-img';

class NavbarComponent extends Component {
    state = {  }
    render() { 
        return ( 
            <Navbar bg="dark" variant="dark" expand="lg">
                <Navbar.Brand>WAVE</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link as={Link} to="/">Home</Nav.Link>
                        <Nav.Link as={Link} to="/feed">Feed</Nav.Link>
                        <Nav.Link as={Link} to="/explore">Explore</Nav.Link>
                    </Nav>
                    <Nav>
                        { this.props.loggedIn ?
                            <Button variant="light" onClick={this.props.showModal}><PlusIcon size={16} />Log</Button>
                            :null
                        }
                        <Form inline>
                            <SearchBox history={this.props.history} />
                        </Form>
                        { this.props.loggedIn ?
                            <Dropdown alignRight>
                                <Dropdown.Toggle>
                                    <ProfileImage size={20}/>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item as={Link} to="/settings">Settings</Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item onClick={this.props.handleLogout}>Logout</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            : null
                        }
                    </Nav>
                </Navbar.Collapse>
                    
            </Navbar>
        );
    }
}
 
export default NavbarComponent;