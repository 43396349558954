import React, { Component } from 'react'
import { withRouter } from 'react-router';
import AlbumService from './../services/album-service';
import { Container, Spinner } from 'react-bootstrap';
import '../styles/album.scss'
import AlbumComment from '../components/album-comment';
import AlbumImage from '../components/album-img';

class AlbumPage extends Component {
    state = { data: null, error: false }

    componentDidMount () {
        const artist = this.props.match.params.artist;
        const name = this.props.match.params.name;
        this.getAlbumData(artist, name);
    }

    getAlbumData (artist, name) {
        AlbumService.getAlbumInfo(artist, name).then(val => {
            this.setState({
                data: val
            })
        }).catch(err => {
            console.error(err);
            this.setState({
                error: true,
                data: null
            })
        })
    }

    render() { 
        return ( 
            <React.Fragment>
                {!this.state.error
                    ? this.state.data
                        ? 
                        <div>
                            <Container fluid="sm"> 
                                <div className="album-header">
                                    <AlbumImage size={300} src={this.state.data.image} /> 
                                    <div className="album-title">{this.state.data.name}</div>
                                    <div className="album-artist">By {this.state.data.artist}</div>
                                    <p>Listeners: {this.state.data.listener_count}</p>
                                </div>
                            </Container>
                            <Container>
                                <div className="album-content">
                                    {this.state.data.comments.map((comment) => {
                                        return <AlbumComment content={comment.content} user={comment.user_id} rating={comment.rating} />
                                    })}
                                    
                                    
                                </div>
                            </Container>
                        </div>
                        : <Spinner animation="border" />
                    : <h1>Error - try retyping the URL</h1>
                }
            </React.Fragment>
        );
    }
}
 
export default withRouter(AlbumPage);