import React, { Component } from 'react'
import { Button, Container, Spinner } from 'react-bootstrap'
// import Typed from 'react-typed';
import ExploreService from '../services/explore-service';
import '../styles/home.scss'
import ExploreAlbum from '../components/explore-album';
import { Link } from 'react-router-dom';


class HomePage extends Component {
    state = { 
        albums_loading: true,
        albums: []
    }

    componentDidMount() {
        ExploreService.getAlbums().then(val => {
            this.setState({
                albums: val,
                albums_loading: false
            })
        })
    }

    render() { 
        return ( 
            <div>
                <Container className="intro-container">
                    <h1 className="intro">The social platform to share 
                    {/* <Typed 
                        strings={[
                            " music",
                            " albums",
                            " singles",
                            " artists"
                        ]}
                        typeSpeed={100}
                        backSpeed={75}
                        loop
                    /> */}
                    </h1>
                    <Button className="signup" as={Link} to="/register">Sign up for free</Button>
                </Container>
                <Container>
                <div className="explore-title">Explore albums</div>
                    {!this.state.albums_loading
                    ? <div className="explore-container">
                        {this.state.albums.map(item => 
                            (<ExploreAlbum data={item} />)
                        )}
                    </div>
                    : <Spinner animation="border"/>
                    }
                </Container>
            </div>
            
         );
    }
}
 
export default HomePage;