import React, { Component } from 'react'
import { Image, Spinner } from 'react-bootstrap'
import userService from '../services/user-service';
import { Link } from 'react-router-dom';
import ProfileImage from './profile-img';

class ExploreUser extends Component {
    getFollowers(followers) {
        if (followers >= 1000000) {
            let decimalFollowers = followers/1000000;
            return decimalFollowers.toFixed(1) + "m"
        }
        else if (followers >= 1000) {
            let decimalFollowers = followers/1000;
            return decimalFollowers.toFixed(1) + "k"
        }
        return followers;
    }

    state = { user: {} }

    render() {
        let { name, profile_img, followers, user_id } = this.props.user;
        return (
        <div className="explore-user">
            <Link to={`/user/${user_id}`}>
                <ProfileImage size={160} src={profile_img} />
                <div className="explore-user-name">{name}</div>
                <div className="explore-user-followers">{followers} followers</div>
                {console.log(followers)}
            </Link>
        </div>
        )
    }
}

export default ExploreUser;