import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AlbumService from '../services/album-service';
import { StarFillIcon } from '@primer/octicons-react';
import { Spinner } from 'react-bootstrap';
import userService from '../services/user-service';
import ProfileImage from '../components/profile-img'
import AlbumImage from './album-img';

class FeedItem extends Component {
    state = { albumart: null, user: null, loading: true }
    componentDidMount() {
        AlbumService.getAlbumArt(this.props.item.artist, this.props.item.album, 2).then(val => {
            this.setState({
                albumart: val,
                album_loading: false
            })
        })
    }
    render() { 
        const { artist, album, profile_img, rating, user_id, name } = this.props.item;
        return ( 
            <div>
                <div className="feed-item">
                    <div className="feed-image">
                        {!this.state.album_loading
                        ?<AlbumImage src={this.state.albumart} />
                        :<AlbumImage />}
                    </div>
                    <div className="feed-content">
                        <div className="feed-user">
                            <span className="feed-profile-img"><ProfileImage size={18} src={profile_img}/></span><Link style={{color: "#212529"}} to={`/user/${user_id}`}>{name}</Link> listened to:
                        </div>
                        <div className="feed-title"><Link style={{color: "#212529"}} to={`/album/${artist}/${album}`}>{album}</Link></div>
                        <div className="feed-artist">By&nbsp;<Link style={{color: "#858585"}} to={`/artist/${artist}`}>{artist}</Link></div>
                        <div className="feed-stars">
                        {
                            [...Array(rating)].map(() => 
                                <StarFillIcon className="feed-star" size={24} />
                            )
                        }
                        </div>
                    </div>
                </div><br/>
            </div>
        );
    }
}
 
export default FeedItem;