import axios from 'axios';
import DataService from './data-service';
import global from '../global';

const url = global.getLastFmUrl();

class ArtistService {
    async getTopAlbums(name) {
        const lastfm_token = await DataService.getLastFmKey();
        let res = await axios.get(url, {
            params: {
                method: "artist.gettopalbums",
                artist: name,
                api_key: lastfm_token,
                format: "json"
            }
        })
        let albums = res.data.topalbums.album.map(album => ({name: album.name, image: album.image[2]["#text"], }))
        return albums;
    }

    async getArtistInfo(artist) {
        const lastfm_token = await DataService.getLastFmKey();
        let res = await axios.get(url, {
            params: {
                method: "artist.getinfo",
                artist: artist,
                api_key: lastfm_token,
                format: "json"
            }
        })
        return res.data.artist;
    }
}

export default new ArtistService();